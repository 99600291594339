import React from "react"
import { Patient } from "../../sharedTypes"
import { OptionItem } from "@parachutehealth/canopy-combobox-field"
import { format } from "utilities/date"

type Props = {
  patient: OptionItem & Patient
}

function PatientSuggestion({ patient }: Props): JSX.Element {
  const sex = patient.sex ? `(${patient.sex.toUpperCase()[0]})` : ""
  const nameAndSex = `${patient.firstName} ${patient.lastName} ${sex}`
  const dob = `DOB: ${format(patient.dateOfBirth)}`
  const mrn = `MRN: ${patient.mrn}`

  return (
    <div>
      <span
        className="canopy-typography-font-weight-bold"
        role="note"
        aria-label="Patient Name and Sex"
      >
        {nameAndSex}
      </span>
      <br />
      <span role="note" aria-label="Patient Date of Birth">
        {dob}
      </span>
      <br />
      {patient.mrn && (
        <span role="note" aria-label="Patient MRN">
          {mrn}
        </span>
      )}
    </div>
  )
}

export default PatientSuggestion
