import React from "react"
import ClearAllLink from "components/ClinicalFilters/ClearAllLink"
import PatientSelect from "components/ClinicalFilters/PatientSelect"
import ClinicalFacilitySelect from "./ClinicalFacilitySelect"
import ClinicianSelect from "./ClinicianSelect"
import FollowerSelect from "./FollowerSelect"
import SupplierSelect from "./SupplierSelect"
import DateRangeFilter from "components/ClinicalFilters/DateRangeFilter"
import { isEmpty } from "utilities/object"
import {
  Category,
  ClinicalFacility,
  Doctor,
  Follower,
  GetIncomingOrdersClinicalFacilitiesQuery,
  GetIncomingOrdersDoctorsQuery,
  GetIncomingOrdersFollowersQuery,
  GetIncomingOrdersProductSubcategoriesQuery,
  GetIncomingOrdersQuery,
  GetIncomingOrdersSuppliersQuery,
  IncomingOrdersUserUpdateMutation,
  Supplier,
} from "graphql/__generated__/graphql"
import { useDebounce } from "hooks/useDebounce"
import {
  ApolloQueryResult,
  FetchResult,
  MutationFunctionOptions,
} from "@apollo/client"
import ProductTypeSelect from "./ProductTypeSelect"

interface Props {
  refetchOrders: (
    variables?
  ) => Promise<ApolloQueryResult<GetIncomingOrdersQuery>>
  loading?: boolean
  initialQuery: string
  setInitialQuery(query: string): void
  followers: Follower[]
  searchFollowers(
    searchTerm: string
  ): Promise<ApolloQueryResult<GetIncomingOrdersFollowersQuery>>
  suppliers: Supplier[]
  searchSuppliers(
    searchTerm: string
  ): Promise<ApolloQueryResult<GetIncomingOrdersSuppliersQuery>>
  clinicalFacilities: ClinicalFacility[]
  searchClinicalFacilities(
    searchTerm: string
  ): Promise<ApolloQueryResult<GetIncomingOrdersClinicalFacilitiesQuery>>
  initialClinicalFacilityIds: string[]
  setInitialClinicalFacilityIds(clinicalFacilityIds: string[]): void
  productSubcategories: Category[]
  searchProductSubcategories(
    searchTerm: string
  ): Promise<ApolloQueryResult<GetIncomingOrdersProductSubcategoriesQuery>>
  initialProductSubcategoryIds: string[]
  setInitialProductSubcategoryIds(productSubcategoryIds: string[]): void
  initialSupplierIds: string[]
  setInitialSupplierIds(supplierIds: string[]): void
  initialFollowerIds: string[]
  setInitialFollowerIds(followerIds: string[]): void
  initialDeliveryDateStart?: string
  setInitialDeliveryDateStart(string): void
  initialDeliveryDateEnd?: string
  setInitialDeliveryDateEnd(string): void
  doctors: Doctor[]
  searchDoctors(
    searchTerm: string
  ): Promise<ApolloQueryResult<GetIncomingOrdersDoctorsQuery>>
  initialDoctorIds: string[]
  setInitialDoctorIds(doctorIds: string[]): void
  updateUserUiPreferences(
    options: MutationFunctionOptions
  ): Promise<FetchResult<IncomingOrdersUserUpdateMutation>>
}

function Filters({
  refetchOrders,
  loading,
  initialQuery,
  setInitialQuery,
  followers,
  searchFollowers,
  suppliers,
  searchSuppliers,
  clinicalFacilities,
  searchClinicalFacilities,
  initialClinicalFacilityIds,
  setInitialClinicalFacilityIds,
  productSubcategories,
  searchProductSubcategories,
  initialProductSubcategoryIds,
  setInitialProductSubcategoryIds,
  initialSupplierIds,
  setInitialSupplierIds,
  initialFollowerIds,
  setInitialFollowerIds,
  initialDeliveryDateStart,
  setInitialDeliveryDateStart,
  initialDeliveryDateEnd,
  setInitialDeliveryDateEnd,
  doctors,
  searchDoctors,
  initialDoctorIds,
  setInitialDoctorIds,
  updateUserUiPreferences,
}: Props) {
  const showClearAllLink =
    initialQuery ||
    initialDeliveryDateStart ||
    initialDeliveryDateEnd ||
    !isEmpty(initialClinicalFacilityIds) ||
    !isEmpty(initialProductSubcategoryIds) ||
    !isEmpty(initialFollowerIds) ||
    !isEmpty(initialSupplierIds) ||
    !isEmpty(initialDoctorIds)

  const fetchClinicalFacilities = async (term: string) => {
    const { data } = await searchClinicalFacilities(term)
    return data?.incomingOrders.clinicalFacilities.map((facility) => ({
      label: facility.name,
      value: facility.id,
    }))
  }

  const handleClinicalFacilityFilterChange = (values: {
    clinicalFacilityIds: string[]
  }) => {
    const { clinicalFacilityIds } = values
    setInitialClinicalFacilityIds(clinicalFacilityIds)
    updateUserUiPreferences({
      variables: { clinicalFacilityIds },
    })
  }

  const fetchFollowers = async (term: string) => {
    const { data } = await searchFollowers(term)
    return data?.incomingOrders.followers.map((follower) => ({
      label: follower.name,
      value: follower.id,
    }))
  }

  const handleFollowerFilterChange = (values: { followerIds: string[] }) => {
    const { followerIds } = values
    setInitialFollowerIds(followerIds)
    updateUserUiPreferences({ variables: { followerIds } })
  }

  const fetchClinicians = async (term: string) => {
    const { data } = await searchDoctors(term)
    return data?.incomingOrders.doctors.map((doctor) => ({
      label: `${doctor.name}`,
      value: doctor.id,
    }))
  }

  const handleClinicianFilterChange = (values: { doctorIds: string[] }) => {
    const { doctorIds } = values
    setInitialDoctorIds(doctorIds)
    updateUserUiPreferences({ variables: { doctorIds } })
  }

  const fetchProductSubcategories = async (term: string) => {
    const { data } = await searchProductSubcategories(term)
    return data?.incomingOrders.productSubcategories.map(
      (productSubcategory) => ({
        label: productSubcategory.name,
        value: productSubcategory.id,
      })
    )
  }

  const handleProductTypeFilterChange = (values: {
    productSubcategoryIds: string[]
  }) => {
    const { productSubcategoryIds } = values
    setInitialProductSubcategoryIds(productSubcategoryIds)
    updateUserUiPreferences({ variables: { productSubcategoryIds } })
  }

  const fetchSuppliers = async (searchTerm) => {
    const { data } = await searchSuppliers(searchTerm)
    const suppliers = data?.incomingOrders?.suppliers || []
    return suppliers.map((supplier) => ({
      label: supplier.name,
      value: supplier.id,
    }))
  }

  const handleSupplierFilterChange = (values) => {
    setInitialSupplierIds(values.supplierIds)
    updateUserUiPreferences({ variables: { supplierIds: values.supplierIds } })
  }

  function handleDeliveryDateFilterChange(values) {
    const { deliveryDateStartAt, deliveryDateEndAt } = values
    setInitialDeliveryDateStart(deliveryDateStartAt)
    setInitialDeliveryDateEnd(deliveryDateEndAt)
    updateUserUiPreferences({
      variables: {
        deliveryDateStartAt: deliveryDateStartAt ? deliveryDateStartAt : "",
        deliveryDateEndAt: deliveryDateEndAt ? deliveryDateEndAt : "",
      },
    })
  }

  const handleOnClear = () => {
    setInitialSupplierIds([])
    setInitialClinicalFacilityIds([])
    setInitialProductSubcategoryIds([])
    setInitialFollowerIds([])
    setInitialDeliveryDateStart("")
    setInitialDeliveryDateEnd("")
    setInitialQuery("")
    setInitialDoctorIds([])
    updateUserUiPreferences({
      variables: {
        clinicalFacilityIds: [],
        productSubcategoryIds: [],
        supplierIds: [],
        doctorIds: [],
        followerIds: [],
        deliveryDateStartAt: "",
        deliveryDateEndAt: "",
      },
    })
  }

  const debouncedRefetch = useDebounce(refetchOrders)
  const handlePatientSelectChange = (query: string) => {
    debouncedRefetch({ patientSearchTerm: query })
    setInitialQuery(query)
  }

  return (
    <div className="clearfix canopy-mt-16x canopy-mb-4x">
      <div className="float-left">
        <div className="d-inline-block v-align-top">
          <PatientSelect
            loading={loading}
            initialQuery={initialQuery}
            onChange={handlePatientSelectChange}
          />
        </div>
        <div className="d-inline-block v-align-top">
          <ClinicalFacilitySelect
            onChange={handleClinicalFacilityFilterChange}
            clinicalFacilities={clinicalFacilities}
            fetchClinicalFacilities={fetchClinicalFacilities}
            initialClinicalFacilityIds={initialClinicalFacilityIds}
          />
        </div>
        <div className="d-inline-block v-align-top">
          <ProductTypeSelect
            fetchProductSubcategories={fetchProductSubcategories}
            onChange={handleProductTypeFilterChange}
            productSubcategories={productSubcategories}
            initialProductSubcategoryIds={initialProductSubcategoryIds}
          />
        </div>
        <div className="d-inline-block v-align-top">
          <ClinicianSelect
            fetchClinicians={fetchClinicians}
            doctors={doctors}
            initialDoctorIds={initialDoctorIds}
            onChange={handleClinicianFilterChange}
          />
        </div>
        <div className="d-inline-block v-align-top">
          <SupplierSelect
            fetchSuppliers={fetchSuppliers}
            onChange={handleSupplierFilterChange}
            suppliers={suppliers}
            initialSupplierIds={initialSupplierIds}
          />
        </div>
        <div className="d-inline-block v-align-top">
          <DateRangeFilter
            initialDeliveryDateStart={initialDeliveryDateStart}
            initialDeliveryDateEnd={initialDeliveryDateEnd}
            onChange={handleDeliveryDateFilterChange}
          />
        </div>
        <div className="d-inline-block v-align-top ">
          <FollowerSelect
            fetchFollowers={fetchFollowers}
            followers={followers}
            initialFollowerIds={initialFollowerIds}
            onChange={handleFollowerFilterChange}
          />
        </div>
      </div>
      {showClearAllLink && (
        <div className="d-inline-block v-align-top canopy-mx-4x float-left">
          <ClearAllLink onClick={handleOnClear} />
        </div>
      )}
    </div>
  )
}

export default Filters
