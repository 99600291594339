// @team @catalog-crew

import { get, put } from "../../../services/api"
import {
  CatalogConsignmentCloset,
  CatalogConsignmentClosetStatus,
  Supplier,
} from "../types/sharedTypes"
import { ServerSideDataGridParams } from "../hooks/useServerSideDataGrid"
import { ApiResponse } from "./utilities"

export type CatalogConsignmentClosetParams = ServerSideDataGridParams & {
  supplierId?: string
  status?: CatalogConsignmentClosetStatus | "All"
}

export type CatalogConsignmentClosetResponse = {
  catalogConsignmentClosets: CatalogConsignmentCloset[]
  suppliers: Supplier[]
  totalCount: number
  pageSize: number
  canEdit: boolean
}
export const getCatalogConsignmentClosets = async (
  params: CatalogConsignmentClosetParams
): Promise<CatalogConsignmentClosetResponse> => {
  return await get(`/cms/catalog/consignment_closets.json`, params).then(
    (response) => {
      return response.data
    }
  )
}

export const updateCatalogConsignmentCloset = async (
  consignmentClosetId: string,
  params: Record<string, any>
): Promise<
  ApiResponse<{ catalogConsignmentCloset: CatalogConsignmentCloset }>
> => {
  return await put(
    `/cms/catalog/consignment_closets/${consignmentClosetId}.json`,
    { catalog_consignment_closet: params }
  ).then((response) => response.data)
}
