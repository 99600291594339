import React from "react"
import InternalCsrInboxStateAiDetails from "applications/CsrInboxStateAiDetails/components/InternalCsrInboxStateAiDetails"

type Props = {
  featureEnabled: boolean
  documentation: {
    title?: string
    summary?: string
    qualificationStatus?: "qualified" | "undetermined"
  }
}
const CsrInboxStateAiDetails = ({ featureEnabled, documentation }: Props) => {
  if (!featureEnabled) {
    return null
  }

  return <InternalCsrInboxStateAiDetails {...documentation} />
}

export default CsrInboxStateAiDetails
