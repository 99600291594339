import React, { createRef, useEffect } from "react"
import * as styles from "./index.module.scss"
import ContactInformation from "./components/ContactInformation"
import SignatureRequestPreferences from "./components/SignatureRequestPreferences"
import SignUp from "../ReviewerSignUp/components/SignUp"
import CantFindClinician from "../ReviewerSignUp/components/CantFindClinician"
import {
  editContactInformationPath,
  editNotificationPreferencesPath,
} from "./urls"
import classNames from "classnames"
import { ReviewerEmployment } from "./sharedTypes"
import { Doctor, SignUpSource } from "sharedTypes"
import Link from "components/Link"
import { ToastProvider } from "components/Toaster"
import { MaterialThemeProvider } from "themes/theme"
import VerifyEmailAlert from "./VerifyEmailAlert"
import Alert from "components/Alert"
import LeftSidebar from "../../components/ClinicalDashboard/LeftSidebar"
import {
  SidebarListDoctor,
  SidebarListFacility,
} from "../../components/ClinicalDashboard/sharedTypes"

type Props = {
  user: {
    firstName: string
    lastName: string
    timeZone: string
    email: string
    mobileNumber: string
    phoneNumber: string
    phoneExtension: string
  }
  reviewerEmployments: ReviewerEmployment[]
  reviewerEmailVerified: boolean
  reviewerUserId: string
  showReviewerSignup?: boolean
  verificationSuccess?: boolean
  removedReviewerEmploymentDoctor: string | undefined
  sidebarInformation: {
    isReviewer: boolean
    lastAccessedFacilityEmployerId?: string
    facilities?: SidebarListFacility[]
    doctors?: SidebarListDoctor[]
    currentUserEligibleForFacilitySignup?: boolean
    hasActionableSignatureRequests?: boolean
  }
  featureFlags: {
    leftSidebarSignatureRequestsSignup: boolean
    facilityIncomingOrders: boolean
    newPillIncomingOrders: boolean
  }
  preSelectedDoctors: Doctor[]
  scrollToAddClinicians: boolean
  reviewerCanReceiveEmrMessages: boolean
}

const Tab = ({
  name,
  path,
  active,
}: {
  name: string
  path: string
  active?: boolean
}) => (
  <div>
    <Link
      className={classNames("font-subparagraph ml-2 pl-2", {
        "color-primary": active,
        bl: active,
        "bw-2": active,
      })}
      href={path}
      role="tab"
      aria-label={name}
    >
      {name}
    </Link>
  </div>
)

const ReviewerUserPreferences = (props: Props) => {
  const active = (path) => path === window.location.pathname
  const showVerifyEmailAlert =
    active(editNotificationPreferencesPath) && !props.reviewerEmailVerified
  const showFacilityDashboardNewPill =
    props.sidebarInformation.currentUserEligibleForFacilitySignup
  const showRemovedEmploymentSuccessAlert =
    active(editNotificationPreferencesPath) &&
    props.removedReviewerEmploymentDoctor

  const addCliniciansRef = createRef<HTMLDivElement>()

  useEffect(() => {
    if (props.scrollToAddClinicians && addCliniciansRef.current) {
      addCliniciansRef.current.scrollIntoView({ behavior: "smooth" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scrollToAddClinicians])

  return (
    <MaterialThemeProvider>
      <ToastProvider>
        <div>
          <LeftSidebar
            sidebarInformation={props.sidebarInformation}
            showFacilityDashboardNewPill={showFacilityDashboardNewPill}
            featureFlags={props.featureFlags}
          />
          <div className="row mt-n3">
            {showRemovedEmploymentSuccessAlert && (
              <Alert status="success" className="w-100-p" bordered>
                <strong>
                  Successfully removed as a reviewer for{" "}
                  {props.removedReviewerEmploymentDoctor}.
                </strong>
              </Alert>
            )}
            {showVerifyEmailAlert && (
              <VerifyEmailAlert reviewerUserId={props.reviewerUserId} />
            )}
            {props.verificationSuccess && (
              <Alert status="success" className="w-100-p">
                <span>
                  <strong>Thanks for verifying your email! </strong>
                  You can now update your signature request email preferences.
                </span>
              </Alert>
            )}
            <div className={`col-12 ${styles.userPreferenceContainer}`}>
              <div
                className={`${styles.userPreferenceNavigationMain}`}
                role="tablist"
              >
                <div className={styles.userPreferenceNavigationContent}>
                  <strong>User Preferences</strong>
                  <div className="mt-3">
                    <Tab
                      name="Contact Information"
                      path={editContactInformationPath}
                      active={active(editContactInformationPath)}
                    />
                    <Tab
                      name="Signature Request Preferences"
                      path={editNotificationPreferencesPath}
                      active={active(editNotificationPreferencesPath)}
                    />
                  </div>
                </div>
              </div>
              <div className={`pl-3 ${styles.mainContent}`} role="tabpanel">
                {active(editContactInformationPath) && (
                  <ContactInformation user={props.user} />
                )}
                {active(editNotificationPreferencesPath) && (
                  <>
                    <SignatureRequestPreferences
                      reviewerEmployments={props.reviewerEmployments}
                      reviewerEmailVerified={props.reviewerEmailVerified}
                      reviewerCanReceiveEmrMessages={
                        props.reviewerCanReceiveEmrMessages
                      }
                    />
                    {props.showReviewerSignup && (
                      <div
                        ref={addCliniciansRef}
                        className={`row ${styles.addCliniciansRow}`}
                      >
                        <div className="col-sm-2">
                          <h3>Add Clinicians</h3>
                        </div>
                        <div
                          className="col-sm-9"
                          style={{ marginLeft: "15px", maxWidth: "600px" }}
                        >
                          <SignUp
                            signUpSource={SignUpSource.Preferences}
                            preSelectedDoctors={props.preSelectedDoctors}
                          />
                          <CantFindClinician
                            className={styles.cantFindClinicianLink}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </ToastProvider>
    </MaterialThemeProvider>
  )
}

export default ReviewerUserPreferences
