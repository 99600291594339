import React, { useContext, useEffect, useState } from "react"
import {
  DataGridPro,
  GridColDef,
  GridLinkOperator,
  GridRowId,
  useGridApiRef,
} from "@mui/x-data-grid-pro"
import { Link } from "react-router-dom"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import { Box, Grid } from "@material-ui/core"
import ContentArea from "applications/Cms/components/ContentArea"
import { CanopyButton } from "@parachutehealth/canopy-button"
import DropdownMenu from "applications/Cms/components/DropdownMenu"
import DataGridToolbar from "applications/Cms/components/DataGridToolbar"
import { isTest } from "utilities/environment"
import {
  CatalogConsignmentClosetParams,
  getCatalogConsignmentClosets,
} from "../../../../api/consignmentClosets"
import useServerSideDataGrid, {
  ColumnFilter,
  ServerSideDataGridOptions,
  ServerSideDataGridParams,
} from "applications/Cms/hooks/useServerSideDataGrid"
import {
  CatalogConsignmentCloset,
  Supplier,
} from "applications/Cms/types/sharedTypes"
import SupplierAutocomplete from "applications/Cms/components/SupplierAutocomplete"
import { initUserInstruments } from "services/clientsideInstrumentation"
import EditConsignmentClosetDrawer from "../EditConsignmentClosetDrawer"
import { NoticeContext } from "../../../../contexts/NoticeContext"

type CatalogConsignmentClosetOptions = ServerSideDataGridOptions & {
  supplierId?: string
}

export type ConsignmentClosetsPageProps = {
  filters?: {
    supplierId?: CatalogConsignmentClosetParams["supplierId"]
    columnFilters?: ColumnFilter[]
    columnFilterLinkOperator?: GridLinkOperator
  }
}

const ConsignmentClosetsPage: React.FC<ConsignmentClosetsPageProps> = (
  props: ConsignmentClosetsPageProps
) => {
  const { filters } = props
  const { showNotice } = useContext(NoticeContext)

  useEffect(() => {
    initUserInstruments()
    document.title = "Consignment Closets"
  }, [])

  const [loading, setLoading] = useState<boolean>(false)

  const beforeFetch = () => {
    setLoading(true)
  }
  const afterFetch = () => {
    setLoading(false)
  }

  const [consignmentClosetsData, setConsignmentClosetsData] = useState<{
    catalogConsignmentClosets: CatalogConsignmentCloset[]
    totalCount: number
    pageSize: number
    canEdit: boolean
  }>({
    catalogConsignmentClosets: [],
    totalCount: 0,
    pageSize: 0,
    canEdit: false,
  })

  const gridApi = useGridApiRef()
  const [suppliersData, setSuppliersData] = useState<Supplier[]>([])

  const defaultOptions: CatalogConsignmentClosetOptions = {
    page: 1,
    sort: undefined,
    supplierId: filters?.supplierId,
  }

  const fetchFunction = async (
    params: ServerSideDataGridParams
  ): Promise<void> => {
    const data = await getCatalogConsignmentClosets(params)
    setConsignmentClosetsData({
      catalogConsignmentClosets: data.catalogConsignmentClosets,
      pageSize: data.pageSize,
      totalCount: data.totalCount,
      canEdit: data.canEdit,
    })
    setSuppliersData(data.suppliers)
  }

  const columns: GridColDef[] = React.useMemo(() => {
    return [
      {
        field: "supplierName",
        flex: 1,
        headerName: "Supplier",
        renderCell: (params) => {
          return (
            <>
              <Link to={params.row?.supplierUrl} tabIndex={params.tabIndex}>
                {(params?.formattedValue || params?.value)?.toString()}
              </Link>
            </>
          )
        },
      },
      {
        field: "clinicalFacilityName",
        flex: 1,
        headerName: "Facility",
        renderCell: (params) => {
          return (
            <>
              <Link
                to={params.row?.clinicalFacilityUrl}
                tabIndex={params.tabIndex}
              >
                {(params?.formattedValue || params?.value)?.toString()}
              </Link>
            </>
          )
        },
      },
      {
        field: "name",
        flex: 1,
        headerName: "Closet",
        renderCell: (params) => {
          return (
            <>
              <Link to={params.row?.url} tabIndex={params.tabIndex}>
                {(params?.formattedValue || params?.value)?.toString()}
              </Link>
            </>
          )
        },
      },
      {
        field: "status",
        flex: 0.5,
        headerName: "Status",
        type: "singleSelect",
        minWidth: 100,
        valueOptions: ["Active", "Not Active"],
        valueGetter: (params) =>
          params.row.active === true ? "Active" : "Not Active",
      },
      {
        field: "floor",
        flex: 0.25,
        headerName: "Floor",
        sortable: false,
        filterable: false,
        hide: true,
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        minWidth: 160,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <DropdownMenu
            label="Actions"
            id="actions-menu"
            buttonProps={{
              variant: "tertiary",
            }}
          >
            {[
              {
                label: "Edit",
                onClick: () => openDrawer(params.row.externalId),
                ifTrue: Boolean(consignmentClosetsData.canEdit),
              },
              {
                // To be completed in WF-24281
                label: "Duplicate Closet",
                ifTrue: Boolean(true),
                onClick: () => {},
              },
              {
                // To be completed in: WF-24294
                label: "Export",
                ifTrue: Boolean(true),
                onClick: () => {},
              },
              {
                // To be completed in: WF-24273
                label: "Delete",
                ifTrue: Boolean(true),
                onClick: () => {},
              },
            ]}
          </DropdownMenu>
        ),
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consignmentClosetsData.canEdit])

  const openDrawer = (rowId: GridRowId) => {
    const consignmentCloset = gridApi.current.getRow(
      rowId
    ) as CatalogConsignmentCloset
    setSelectedConsignmentCloset(consignmentCloset)
  }

  const onSuccessfulUpdate = (
    updatedConsignmentCloset: CatalogConsignmentCloset
  ) => {
    const newConsignmentClosets = consignmentClosetsData.catalogConsignmentClosets.map(
      (consignmentCloset) =>
        consignmentCloset.id === updatedConsignmentCloset.id
          ? updatedConsignmentCloset
          : consignmentCloset
    )
    setConsignmentClosetsData({
      catalogConsignmentClosets: newConsignmentClosets,
      pageSize: consignmentClosetsData.pageSize,
      totalCount: consignmentClosetsData.totalCount,
      canEdit: consignmentClosetsData.canEdit,
    })
    showNotice(
      `Changes have been saved for the ${updatedConsignmentCloset.name} Closet.`,
      "success"
    )
  }

  const {
    filterModel,
    options,
    handlePageChange,
    handleFilterChange,
    handleFilterModelChange,
    handleSortModelChange,
  } = useServerSideDataGrid<CatalogConsignmentClosetOptions>({
    defaultFilterModel: {
      columnFilters: [],
      columnFilterLinkOperator: GridLinkOperator.And,
    },
    defaultOptions,
    columnDefinitions: columns,
    fetchFunction,
    beforeFetch,
    afterFetch,
  })

  const [
    selectedConsignmentCloset,
    setSelectedConsignmentCloset,
  ] = React.useState<CatalogConsignmentCloset | undefined>()

  const closeEditDrawer = () => {
    setSelectedConsignmentCloset(undefined)
  }

  const useDrawer = (trigger) => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
      setIsOpen(!!trigger)
    }, [trigger])

    return isOpen
  }

  const editConsignmentClosetOpen = useDrawer(selectedConsignmentCloset)

  return (
    <>
      <Box mt={1} mb={3}>
        <h2 className="canopy-typography-heading-2xlarge">
          Consignment Closets
        </h2>
        <p className="canopy-typography-body-medium">
          Physical closets that exist in facilities for easy access of supplies.
          They are composed of a grouping of Supplier Package Configurations.
        </p>
      </Box>
      <ContentArea>
        <>
          <Box className="index-filter">
            <Grid container spacing={2}>
              <Grid xs={12} sm={3} lg={3} item>
                <SupplierAutocomplete
                  className="supplier-filter"
                  onChange={(newValue: string | string[] | null) =>
                    handleFilterChange("supplierId", newValue)
                  }
                  disabled={loading}
                  suppliers={suppliersData}
                  selectedValue={options?.supplierId}
                  data-testid="supplier-filter"
                />
              </Grid>
              <Grid xs={12} sm={9} lg={9} item>
                <Box mb={2} display="flex" justifyContent="flex-end">
                  <CanopyButton
                    variant="primary"
                    size="small"
                    // To be completed in WF-24274
                    // disabled={!Boolean(consignmentClosetsData.permissions?.canAddNewConsignmentCloset)}
                    // onClick={() => history.push(consignmentClosetCreateUrl())}
                  >
                    New Closet
                  </CanopyButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className={classNames(styles.consignmentClosetsDatagrid)}>
            <DataGridPro
              apiRef={gridApi}
              loading={loading}
              className="borderless"
              rows={consignmentClosetsData.catalogConsignmentClosets}
              rowCount={consignmentClosetsData.totalCount}
              autoHeight
              filterMode="server"
              filterModel={filterModel}
              onFilterModelChange={handleFilterModelChange}
              density="standard"
              columns={columns}
              pagination={true}
              page={options.page - 1} // account for DataGrid's zero-based indexing
              pageSize={consignmentClosetsData.pageSize}
              paginationMode="server"
              rowsPerPageOptions={[consignmentClosetsData.pageSize]}
              onPageChange={(page) => {
                handlePageChange(page + 1)
              }} // account for DataGrid's zero-based indexing
              getRowId={(row) => row.externalId}
              sortingMode="server"
              sortModel={options.sort}
              onSortModelChange={handleSortModelChange}
              disableVirtualization={isTest()} // Needs to be true for tests to work but ideally false in production, esp. for higher row counts
              hideFooterSelectedRowCount
              components={{
                Toolbar: DataGridToolbar,
              }}
              componentsProps={{
                toolbar: { filter: true },
              }}
            />
          </Box>
          {selectedConsignmentCloset && (
            <EditConsignmentClosetDrawer
              open={editConsignmentClosetOpen}
              onClose={closeEditDrawer}
              selectedConsignmentCloset={selectedConsignmentCloset}
              onSuccess={onSuccessfulUpdate}
            />
          )}
        </>
      </ContentArea>
    </>
  )
}

export default ConsignmentClosetsPage
