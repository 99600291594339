// @team @clinical-intelligence
import React from "react"
import ReviewCard from "."
import {
  DmeOrder,
  DmeOrderWarning,
  Employer,
  DocumentationRequirementSatisfyStatus,
  Severity,
} from "sharedTypes"
import { useFeatureFlags } from "components/FeatureFlagContext"
import {
  requestedDocumentationRequirements,
  titleWithCategory,
  titleWithUsageContext,
} from "applications/Workflow/utilities/documentationRequirements"
import DocumentationRequirementRow from "../../../ClinicalFacilityClinical/components/DocumentationRequirements/DocumentationRequirementRow"
import MedicalNecessitySummaryRow from "./MedicalNecessitySummaryRow"
import { isChartNoteRelatedToDocumentationRequirement } from "applications/Workflow/containers/ClinicalFacilityClinical/components/utilities"
import MedicalNecessityRow from "./MedicalNecessityRow"
import { DocumentationRequirementStatus } from "applications/Workflow/containers/ClinicalFacilityClinical/components/DocumentationRequirements/DocumentationRequirementRow/DocumentationRequirementStatus"
import ExternalQuestionnaireStatusPill from "../ExternalQuestionnaireStatusPill"
import { ExternalQuestionnaire } from "../../sharedTypes"

type Props = {
  currentEmployer: Employer
  dmeOrder: DmeOrder
  expanded: boolean
  warnings: DmeOrderWarning[]
  externalQuestionnaires?: ExternalQuestionnaire[]
}

const statusSeverity = {
  [DocumentationRequirementSatisfyStatus.Complete]: 2,
  [DocumentationRequirementSatisfyStatus.Incomplete]: 1,
  [DocumentationRequirementSatisfyStatus.Unstarted]: 1,
  [DocumentationRequirementSatisfyStatus.Invalid]: 0,
}

const statusToSeverity = (status) => {
  switch (status) {
    case DocumentationRequirementSatisfyStatus.Complete:
      return Severity.Success
    case DocumentationRequirementSatisfyStatus.Incomplete:
    case DocumentationRequirementSatisfyStatus.Unstarted:
      return Severity.Warning
    case DocumentationRequirementSatisfyStatus.Invalid:
      return Severity.Danger
  }
}

const determineStatus = (recommendation, status) => {
  if (status === "Incomplete" || status === "Not Started") {
    return status
  }
  return recommendation
}

function MedicalNecessityReviewCard({
  dmeOrder,
  expanded,
  warnings,
  externalQuestionnaires,
}: Props) {
  const { isFeatureEnabled } = useFeatureFlags()
  const contextDrivenDocs = isFeatureEnabled("contextDrivenDocs")
  const demoClinicalGuidelines =
    isFeatureEnabled("demoClinicalGuidelines") && dmeOrder.optum

  const { chartNotes } = dmeOrder
  const visibleDocumentationRequirements = requestedDocumentationRequirements(
    dmeOrder.documentationRequirements
  )
  externalQuestionnaires ||= []

  const questionnairesArray = demoClinicalGuidelines
    ? []
    : externalQuestionnaires.map((questionnaire) => ({
        title: questionnaire.label,
        questionnaire: questionnaire.actions,
        recommendation: "Recommended", //to be filled out later, these are hardcoded values temporarily
        status: "Completed",
      }))

  const productConfigurationWarning = warnings.find(
    (warning) =>
      warning.key === "rx_details" || warning.key === "product_requirements"
  )

  const summary = [
    ...visibleDocumentationRequirements.map((d) => (
      <MedicalNecessitySummaryRow
        title={
          contextDrivenDocs ? titleWithUsageContext(d) : titleWithCategory(d)
        }
        statusPill={
          <DocumentationRequirementStatus satisfyStatus={d.satisfyStatus} />
        }
        key={`Key - ${
          contextDrivenDocs ? titleWithUsageContext(d) : titleWithCategory(d)
        }`}
      />
    )),
    ...questionnairesArray.map(
      ({ title, questionnaire, recommendation, status }) => (
        <MedicalNecessitySummaryRow
          title={title}
          questionnaire={questionnaire}
          statusPill={
            <ExternalQuestionnaireStatusPill
              satisfyStatus={determineStatus(recommendation, status)}
            />
          }
          key={`Key - ${title}`}
        />
      )
    ),
  ]

  const mostSevereStatus = visibleDocumentationRequirements
    .map((d) => d.satisfyStatus)
    .sort(
      (statusA, statusB) => statusSeverity[statusA] - statusSeverity[statusB]
    )[0]
  const severity = statusToSeverity(mostSevereStatus)

  const expandedContent = () => {
    if (
      visibleDocumentationRequirements.length === 0 &&
      questionnairesArray.length === 0
    )
      return

    return (
      <div className="well p-3">
        {visibleDocumentationRequirements.map(
          (documentationRequirement, index) => (
            <div key={documentationRequirement.externalId}>
              {index !== 0 && <hr />}
              <DocumentationRequirementRow
                dmeOrderId={dmeOrder.id}
                chartNote={chartNotes.find((cn) =>
                  isChartNoteRelatedToDocumentationRequirement(
                    documentationRequirement,
                    cn,
                    contextDrivenDocs
                  )
                )}
                documentationRequirement={documentationRequirement}
                permissions={{
                  updateChartNotes: false,
                  updateManualDocumentation: false,
                }}
                missingChartNotesPrerequisites={!!productConfigurationWarning}
                bounceToSection={{
                  shouldBounce: false,
                  triggerBounce: () => {},
                }}
                detailed={false}
              />
            </div>
          )
        )}
        {questionnairesArray.map(
          ({ title, questionnaire, recommendation, status }) => (
            <div key={title}>
              <hr />
              <MedicalNecessityRow
                title={title}
                questionnaire={questionnaire}
                recommendation={determineStatus(recommendation, status)}
              />
            </div>
          )
        )}
      </div>
    )
  }

  return (
    <ReviewCard
      status={severity}
      expanded={expanded}
      summary={summary}
      title="Medical Necessity Review"
    >
      {expandedContent()}
    </ReviewCard>
  )
}

export default MedicalNecessityReviewCard
