// @team @facilitators
import React from "react"
import { Supplier } from "../../../../../../sharedTypes"
import { CanopyIcon } from "@parachutehealth/canopy-icon"

type Props = {
  isPreferredSupplier: boolean
  supplier: Supplier
}

function SearchByProductSupplierLabel({
  isPreferredSupplier,
  supplier,
}: Props) {
  return (
    <>
      {supplier.name}
      {isPreferredSupplier && (
        <>
          <hr className="my-3" />
          <div className="pill pill-shadow pill-success d-inline-flex flex-row align-items-center pl-2 canopy-typography-body-small">
            <CanopyIcon
              name="shield-check"
              size="medium"
              fill="canopyColorTextSuccess"
            />
            <div className="font-notice">Preferred</div>
          </div>
        </>
      )}
    </>
  )
}

export default SearchByProductSupplierLabel
