import React from "react"
import Checkbox from "components/form/Checkbox"
import Alert from "components/Alert"
import {
  titleWithCategory,
  titleWithUsageContext,
} from "../../utilities/documentationRequirements"
import { DocumentationRequirement } from "sharedTypes"
import { useFeatureFlags } from "components/FeatureFlagContext"
interface Props {
  requirements: DocumentationRequirement[]
  signed?: boolean
}
const GenerateRequirementsSection = ({ requirements, signed }: Props) => {
  const { isFeatureEnabled } = useFeatureFlags()
  const contextDrivenDocs = isFeatureEnabled("contextDrivenDocs")

  if (requirements.length === 0) {
    return <></>
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col-1">
          <span className="logo logo-sm">
            <div className="image"></div>
          </span>
        </div>
        <div className="col-11 pl-0">
          <span className="color-dark-gray">Parachute Documents</span>
        </div>
      </div>
      <div className="row mb-3">
        <div className="offset-1 col-11 pl-0">
          <Checkbox
            name="signed"
            label="All Parachute-Generated Documents"
            type={Checkbox.Type.Basic}
            primary
            unwrapped
          />
          <ul className="mt-2">
            {requirements.map((requirement) => (
              <li key={requirement.externalId}>
                {contextDrivenDocs
                  ? titleWithUsageContext(requirement)
                  : titleWithCategory(requirement)}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {signed && (
        <div className="row mb-4">
          <div className="col-12">
            <Alert leftIcon status="info">
              Signature status of this order will be marked complete
            </Alert>
          </div>
        </div>
      )}
    </>
  )
}

export default GenerateRequirementsSection
