import React from "react"
import { CatalogConsignmentCloset } from "../../../../types/sharedTypes"
import { updateCatalogConsignmentCloset } from "applications/Cms/api/consignmentClosets"
import { Drawer, makeStyles } from "@material-ui/core"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { Form } from "components/form"
import { CanopyCheckboxInput } from "@parachutehealth/canopy-checkbox-input"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { AxiosError } from "axios"
import { CanopySelectField } from "@parachutehealth/canopy-select-field"

export interface EditConsignmentClosetDrawerProps {
  open: boolean
  onClose(): void
  selectedConsignmentCloset: CatalogConsignmentCloset
  onSuccess: (consignmentCloset: CatalogConsignmentCloset) => void
}

const EditConsignmentClosetDrawer = ({
  open,
  onClose,
  selectedConsignmentCloset,
  onSuccess,
}: EditConsignmentClosetDrawerProps) => {
  const initialValues = {
    supplierName: selectedConsignmentCloset.supplierName,
    clinicalFacilityName: selectedConsignmentCloset.clinicalFacilityName,
    name: selectedConsignmentCloset.name,
    active: selectedConsignmentCloset.active,
    floor: selectedConsignmentCloset.floor,
  }

  const handleSubmit = async (params, { setSubmitting, setErrors }) => {
    setSubmitting(true)
    await updateCatalogConsignmentCloset(
      selectedConsignmentCloset.externalId,
      params
    )
      .then((data) => {
        onSuccess(data.catalogConsignmentCloset)
        onClose()
      })
      .catch(
        (
          error: AxiosError<{
            message?: string
            errors?: Record<string, string[]>
          }>
        ) => {
          if (error.response) {
            setErrors(error.response.data.errors || { name: ["bad request"] })
          } else {
            setErrors({ name: ["An unexpected error occurred"] })
          }
        }
      )
    setSubmitting(false)
  }

  const useStyles = makeStyles((theme) => ({
    drawer: {
      boxShadow: theme.shadows[16],
    },
  }))
  const classes = useStyles()

  return (
    <Drawer
      variant="persistent"
      open={open}
      anchor="right"
      className={classNames(styles.editConsignmentClosetDrawer)}
      transitionDuration={500}
      classes={{
        paper: classes.drawer,
      }}
    >
      <div className="p-4 d-flex justify-content-space-between align-items-center border-bottom bg-default">
        <div className="canopy-typography-heading-xlarge canopy-mr-4x canopy-mb-0">
          Edit Closet
        </div>
        <button
          aria-label="Close"
          className="close"
          type="button"
          onClick={onClose}
        >
          <CanopyIcon
            name="xmark"
            size="medium"
            fill="canopyColorPrimitivesGray27"
          ></CanopyIcon>
        </button>
      </div>
      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        {({ errors, isSubmitting, values, handleChange }) => {
          return (
            <div className="canopy-pt-16x canopy-px-16x">
              <CanopySelectField
                label="Supplier"
                required
                disabled
                options={[
                  {
                    value: selectedConsignmentCloset.supplierName,
                    label: selectedConsignmentCloset.supplierName,
                  },
                ]}
              />
              <CanopySelectField
                label="Facility"
                required
                disabled
                className="canopy-pt-8x"
                options={[
                  {
                    value: selectedConsignmentCloset.clinicalFacilityName,
                    label: selectedConsignmentCloset.clinicalFacilityName,
                  },
                ]}
              />
              <CanopyTextInputField
                label="Closet name"
                name="name"
                value={values.name}
                onChange={handleChange}
                feedbackMessage={errors["name"]}
                className="canopy-pt-8x"
                required
              />
              <CanopyTextInputField
                placeholder="Floor number"
                label="Floor"
                name="floor"
                value={values.floor}
                onChange={handleChange}
                className="canopy-pt-8x"
              />
              <CanopyCheckboxInput
                label="Active"
                onChange={handleChange}
                defaultChecked={values.active}
                name="active"
                id="active"
                className="canopy-py-2x canopy-mt-8x canopy-pl-0"
              />
              <div className="canopy-pt-8x">
                <CanopyButton
                  variant="primary"
                  type="submit"
                  size="small"
                  loading={isSubmitting}
                  className="canopy-mr-4x"
                >
                  Save Changes
                </CanopyButton>
                <CanopyButton
                  variant="tertiary"
                  type="button"
                  size="small"
                  onClick={onClose}
                >
                  Cancel
                </CanopyButton>
              </div>
            </div>
          )
        }}
      </Form>
    </Drawer>
  )
}

export default EditConsignmentClosetDrawer
