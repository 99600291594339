import React, { useContext, useEffect, useState } from "react"
import { Route, RouteComponentProps, withRouter } from "react-router-dom"
import { parse } from "utilities/querystring"
import { loadCatalogSettings } from "./api"
import QuickList from "../QuickList"
import * as routes from "applications/Workflow/routes"
import {
  CatalogBrowseTab,
  DmeOrder,
  Employer,
  SearchWorkflow,
} from "sharedTypes"
import { EmployerType } from "utilities/url"
import withInitialData from "components/withInitialData"
import { handleError } from "utilities/error"
import WorkflowPageContext from "context/WorkflowPage"
import { SidebarTab } from "../Navigation/sharedTypes"
import { isSidebarFullScreen } from "applications/Workflow/utilities/sidebar"
import { useFeatureFlags } from "../../../../components/FeatureFlagContext"
import PackageConfiguration from "../PackageConfiguration/PackageConfiguration"
import ProductsAndServicesHeader from "./ProductsAndServicesHeader"
import SearchByProductTab from "./SearchByProductTab"
import SearchBySupplierTab from "applications/Workflow/containers/Product/SearchBySupplierTab"
import ProductSearchTabsSubheader from "./CatalogBrowseTabsSubheader"
import { allowSearchByProduct } from "../../utilities/searchByProduct"

export const ALL_SUPPLIERS = "all"
export const LIST_SUPPLIERS = "list"

interface InitialData {
  defaultBrowseAll: boolean
}

interface Props extends RouteComponentProps {
  initialData: InitialData
  dmeOrder: DmeOrder
  refreshDmeOrder: () => Promise<void>
  currentEmployer: Employer
  goToNextPage: () => Promise<void>
}

const isFacility = () => {
  return (["ClinicalFacility", "ClinicalOrganization"] as (
    | EmployerType
    | undefined
  )[]).includes(window?.parachute?.employerType as EmployerType | undefined)
}

const Product: React.FC<Props> = (props: Props) => {
  const {
    history,
    dmeOrder,
    refreshDmeOrder,
    currentEmployer,
    goToNextPage,
    initialData,
  } = props

  const { setSidebarTab } = useContext(WorkflowPageContext)
  const { isFeatureEnabled } = useFeatureFlags()
  useEffect(() => {
    const autoOpenTabFromNavigationSource = () =>
      (history.location?.state as any)?.autoOpenCartTab

    const shouldAutomaticallyOpenCartTab =
      autoOpenTabFromNavigationSource() &&
      isFacility() &&
      dmeOrder.lineItemGroups.length > 0 &&
      !isSidebarFullScreen()

    if (shouldAutomaticallyOpenCartTab) {
      setSidebarTab(SidebarTab.Cart)
    }
  }, [setSidebarTab, dmeOrder, history])

  const { defaultBrowseAll } = initialData
  const isSearchByProductEnabled = allowSearchByProduct(
    isFeatureEnabled("userActivationProductFirstSearch"),
    isFeatureEnabled("userActivationProductFirstSearchIncludeEnterprise"),
    dmeOrder.clinicalFacility.usesEnterpriseFeatures
  )
  const shouldDefaultToProductTab =
    isSearchByProductEnabled && defaultBrowseAll && !dmeOrder.supplier
  const [currentTab, setCurrentTab] = useState<CatalogBrowseTab>(
    shouldDefaultToProductTab
      ? CatalogBrowseTab.Product
      : CatalogBrowseTab.Supplier
  )
  const [showSubheader, setShowSubheader] = useState(true)
  useEffect(() => {
    if (currentTab === CatalogBrowseTab.Product) setShowSubheader(true)
  }, [setShowSubheader, currentTab])

  const [
    yourOrganizationsSuppliersOnly,
    setYourOrganizationsSuppliersOnly,
  ] = useState<boolean>(
    dmeOrder.clinicalFacility.yourOrganizationsSuppliersOnlyDefault
  )

  const toggleYourOrganizationsSuppliersOnly = () => {
    setYourOrganizationsSuppliersOnly(
      (yourOrganizationsSuppliersOnly) => !yourOrganizationsSuppliersOnly
    )
  }

  const dmeOrderSupplier = dmeOrder.supplier
  const matchSupplierId = (match): string | undefined => {
    if (dmeOrderSupplier) {
      return dmeOrderSupplier.externalId
    }
    if (match.params.supplierId) {
      return match.params.supplierId
    }
  }

  const searchWorkflow = () => {
    switch (currentTab) {
      case CatalogBrowseTab.Product:
        return SearchWorkflow.SearchByProduct
      case CatalogBrowseTab.Supplier:
        return SearchWorkflow.SupplierFirstSearch
    }
  }

  const setSelectedTab = (tab: CatalogBrowseTab) => {
    setCurrentTab(tab)
    history.push(
      routes.productsPath(
        tab === CatalogBrowseTab.Supplier ? LIST_SUPPLIERS : ALL_SUPPLIERS
      )
    )
  }

  return (
    <>
      <Route
        path={routes.productsPath.matcher}
        render={({ match }) => {
          if (
            isSearchByProductEnabled &&
            match.params.supplierId === ALL_SUPPLIERS
          ) {
            setCurrentTab(CatalogBrowseTab.Product)
          } else if (
            // when shouldDefaultToProductTab is true, we need this check
            // to ensure that page refreshes while on the Search By Supplier tab
            // work properly
            isSearchByProductEnabled &&
            match.params.supplierId === LIST_SUPPLIERS
          ) {
            setCurrentTab(CatalogBrowseTab.Supplier)
          }
          if (isSearchByProductEnabled && !dmeOrder.supplier) {
            return (
              <>
                <ProductsAndServicesHeader
                  currentTab={currentTab}
                  setSelectedTab={setSelectedTab}
                  showSearchByProductTabFirst={shouldDefaultToProductTab}
                />
                {showSubheader && (
                  <ProductSearchTabsSubheader
                    selectedTab={currentTab}
                    setSelectedTab={setSelectedTab}
                  />
                )}
                {currentTab === CatalogBrowseTab.Supplier ? (
                  <SearchBySupplierTab
                    currentTab={currentTab}
                    match={match}
                    dmeOrder={dmeOrder}
                    defaultBrowseAll={defaultBrowseAll}
                    yourOrganizationsSuppliersOnly={
                      yourOrganizationsSuppliersOnly
                    }
                    toggleYourOrganizationsSuppliersOnly={
                      toggleYourOrganizationsSuppliersOnly
                    }
                    setShowSubheader={setShowSubheader}
                  />
                ) : (
                  <SearchByProductTab
                    dmeOrder={dmeOrder}
                    match={match}
                    currentTab={currentTab}
                    yourOrganizationsSuppliersOnly={
                      yourOrganizationsSuppliersOnly
                    }
                    toggleYourOrganizationsSuppliersOnly={
                      toggleYourOrganizationsSuppliersOnly
                    }
                  />
                )}
              </>
            )
          }

          return (
            <SearchBySupplierTab
              currentTab={currentTab}
              match={match}
              dmeOrder={dmeOrder}
              defaultBrowseAll={defaultBrowseAll}
              yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
              toggleYourOrganizationsSuppliersOnly={
                toggleYourOrganizationsSuppliersOnly
              }
              setShowSubheader={setShowSubheader}
            />
          )
        }}
      />
      <Route
        path={routes.productsPackageConfigurationPath.matcher}
        render={({ match }) => {
          const supplierId = matchSupplierId(match)
          const returnToBrowse = () => {
            return refreshDmeOrder()
              .then(() => history.push(routes.productsPath(supplierId)))
              .catch(handleError)
          }
          const initialSelection = !!parse(history.location.search).selecting
          return (
            <PackageConfiguration
              currentEmployer={currentEmployer}
              dmeOrder={dmeOrder}
              history={history}
              packageConfigurationId={match.params.packageConfigurationId}
              refreshDmeOrder={refreshDmeOrder}
              returnToBrowse={returnToBrowse}
              goToNextPage={goToNextPage}
              supplierId={supplierId}
              forceShowRxDetails={
                initialSelection &&
                dmeOrder.clinicalFacility.alwaysShowRxDetails
              }
              yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
              initialSelection={initialSelection}
              searchWorkflow={searchWorkflow()}
            />
          )
        }}
      />
      <Route
        path={routes.productsQuickListPath.matcher}
        render={({ match }) => {
          const supplierId = matchSupplierId(match)
          return (
            <QuickList
              formularyPriceEnabled={
                dmeOrder.clinicalFacility.formularyPriceEnabled
              }
              history={history}
              supplierId={supplierId}
              refreshDmeOrder={refreshDmeOrder}
            />
          )
        }}
      />
    </>
  )
}

const fetchInitialCatalogData = () =>
  loadCatalogSettings().then(({ data }) => data)

export default withInitialData(fetchInitialCatalogData)(withRouter(Product))
