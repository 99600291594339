import LeftSidebar from "components/ClinicalDashboard/LeftSidebar"
import SignUpPromo from "./components/SignUpPromo"
import SignUp from "./components/SignUp"
import CantFindClinician from "./components/CantFindClinician"
import React from "react"
import { Doctor, FeatureFlagInput, SignUpSource } from "sharedTypes"
import { SidebarListFacility } from "components/ClinicalDashboard/sharedTypes"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import ReviewerAtDesk from "reviewer-at-desk.svg"
import DoctorAndReceptionist from "doctor-and-receptionist.svg"
import CustomerService from "customer-service.svg"

type Props = {
  sidebarInformation: {
    isReviewer: boolean
    lastAccessedFacilityEmployerId?: string
    facilities?: SidebarListFacility[]
  }
  currentUserEligibleForReviewerSignUp: boolean
  featureFlags: FeatureFlagInput & {
    leftSidebarSignatureRequestsSignup: boolean
    facilityIncomingOrders: boolean
    newPillIncomingOrders: boolean
  }
  preSelectedDoctors: Doctor[]
  signUpSource?: SignUpSource
}

const ReviewerSignUp = ({
  sidebarInformation,
  currentUserEligibleForReviewerSignUp,
  featureFlags,
  preSelectedDoctors,
  signUpSource,
}: Props) => {
  return (
    <div className="position-relative">
      <LeftSidebar
        active={LeftSidebar.Options.SignatureRequests}
        sidebarInformation={sidebarInformation}
        showSignatureRequestsDashboardNewPill={
          currentUserEligibleForReviewerSignUp
        }
        featureFlags={featureFlags}
        expandLeftSidebar={true}
      />
      <div>
        <div className={classNames(styles.reviewerSignUpContainer, "row")}>
          <SignUpPromo />
          <div
            className={classNames(
              styles.clinicianSelectContainer,
              "col-lg-6 col-12",
              styles.containerPadding
            )}
          >
            <SignUp
              buttonFullWidth={true}
              signUpSource={signUpSource || SignUpSource.Sidebar}
              preSelectedDoctors={preSelectedDoctors}
            />
            <CantFindClinician className={styles.cantFindClinicianLink} />
          </div>
        </div>
        <div className="d-flex justify-content-space-between">
          <PromoImage
            imgSrc={ReviewerAtDesk}
            text="View requests on a single dashboard, and avoid sorting through duplicate faxes"
            alt="Reviewer dashboard"
            imgWidth={91}
          />
          <PromoImage
            imgSrc={DoctorAndReceptionist}
            text="Review requests for clinician to sign on screen, similar to new patient orders"
            alt="Review requests"
            imgWidth={132}
          />
          <PromoImage
            imgSrc={CustomerService}
            text="Track request status in the Parachute Platform"
            alt="Track requests"
            imgWidth={123}
          />
        </div>
      </div>
    </div>
  )
}

const PromoImage = ({
  imgSrc,
  text,
  alt,
  imgWidth,
}: {
  imgSrc: string
  text: string
  alt: string
  imgWidth: number
}) => (
  <div className={styles.flexContainer}>
    <div className={styles.triptychContainer}>
      <div className={styles.imageContainer}>
        <img src={imgSrc} alt={alt} style={{ width: `${imgWidth}px` }} />
      </div>
      <div className="canopy-typography-heading-small text-center">{text}</div>
    </div>
  </div>
)

export default ReviewerSignUp
