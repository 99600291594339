import * as React from "react"
import * as styles from "./index.module.scss"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { isNullOrUndefined } from "../../../../../utilities/isNullOrUndefined"
import classNames from "classnames"
import { GridOverlay } from "@mui/x-data-grid-pro"

type Props = {
  message?: string
  ctaButtonText?: string
  ctaButtonAction?: () => void
  dataGrid?: boolean
}

export const DEFAULT_MESSAGE: string = "There are no rows available to display"

/**
 * A simple component used to indicate that no rows are available within a table or DataGrid.
 * For the latter, intended to be used in the NoRowsOverlay slot, but it can also be used a generic element
 * outside of DataGrids as well.
 *
 * Can optionally have a CTA button as well.
 */
const NoRows = (props: Props): JSX.Element => {
  const {
    message = DEFAULT_MESSAGE,
    ctaButtonText,
    ctaButtonAction,
    dataGrid = true,
  } = props

  const MessageContainer: JSX.Element = (
    <div className="canopy-typography-body-medium">{message}</div>
  )
  const CtaButton = (): JSX.Element => {
    if (
      isNullOrUndefined(ctaButtonText) ||
      isNullOrUndefined(ctaButtonAction)
    ) {
      return <></>
    }

    return (
      <div className="canopy-mt-8x">
        <CanopyButton
          test-id="no-rows-cta"
          variant="tertiary"
          className={styles.textButton}
          onClick={ctaButtonAction}
        >
          {ctaButtonText}
        </CanopyButton>
      </div>
    )
  }

  const noRows = () => {
    return (
      <div className={classNames(styles.container)}>
        <div>{MessageContainer}</div>
        <CtaButton />
      </div>
    )
  }

  return dataGrid ? <GridOverlay>{noRows()}</GridOverlay> : noRows()
}

export default NoRows
