// @team @facilitators
import React from "react"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { RadioButtons } from "components/form"
import { Accordion, AccordionSummary } from "./Accordion"
import { AccordionDetails } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import SearchByProductSupplierLabel from "./SearchByProductSupplierLabel"
import {
  filterOutPreferredSuppliers,
  sortSuppliersByName,
} from "../../utilities/supplier"
import { Supplier } from "sharedTypes"

type Props = {
  availableSuppliers: Supplier[]
  preferredSuppliers: Supplier[]
  onSupplierSelect: (event: any) => void
}

const PreferredSupplierSelector: React.FC<Props> = ({
  availableSuppliers,
  preferredSuppliers,
  onSupplierSelect,
}) => {
  const preferredSupplierIds = preferredSuppliers.map((ps) => ps.externalId)

  const supplierOption = (supplier) => {
    return {
      label: (
        <SearchByProductSupplierLabel
          isPreferredSupplier={preferredSupplierIds.includes(
            supplier.externalId
          )}
          supplier={supplier}
        />
      ),
      value: supplier.externalId,
    }
  }

  const preferredOptions = preferredSuppliers
    .sort(sortSuppliersByName)
    .map((s) => supplierOption(s))

  const notPreferredOptions = filterOutPreferredSuppliers(
    preferredSupplierIds,
    availableSuppliers
  ).map((s) => supplierOption(s))

  const radioSupertext = (forPreferredSupplier) => {
    const message = forPreferredSupplier
      ? "These suppliers are most likely to accept and fulfill your order due to insurance requirements."
      : "These suppliers may be unable to accept your order due to insurance requirements."

    return (
      <div className="canopy-pb-12x d-flex flex-row align-items-end canopy-typography-body-small">
        <CanopyIcon
          name={forPreferredSupplier ? "shield-check" : "shield-slash"}
          size="medium"
          fill={
            forPreferredSupplier
              ? "canopyColorTextSuccess"
              : "canopyColorTextDisabled"
          }
          className="pr-1"
        />
        {message}
      </div>
    )
  }

  return (
    <>
      <RadioButtons
        label="Health Plan Preferred Suppliers"
        name="supplierId"
        supertext={radioSupertext(true)}
        options={preferredOptions}
        onChange={onSupplierSelect}
      />
      <Accordion className="mb-4">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <span className="font-weight-bold">Other Suppliers</span>
        </AccordionSummary>
        <AccordionDetails className="d-block">
          {radioSupertext(false)}

          <RadioButtons
            name="supplierId"
            options={notPreferredOptions}
            onChange={onSupplierSelect}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default PreferredSupplierSelector
