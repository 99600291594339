import { EventCategory, trackEvent } from "../../../../../../utilities/tracking"
import React from "react"
import Alert from "../../../../../../components/Alert"
import { Supplier } from "../../../../../../sharedTypes"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { useFormikContext } from "formik"

type Props = {
  preferredSupplierSelected: boolean
  selectedSupplier: Supplier
  availableSuppliers: Supplier[]
  preferredSuppliers: Supplier[]
  showSupplierSelect(boolean): void
}

const PreferredSupplierRedirect = (
  selectedSupplier: Supplier,
  preferredSuppliers: Supplier[],
  showSupplierSelect: (boolean) => void
) => {
  const { setFieldValue } = useFormikContext()

  return (
    <Alert bordered status="warning">
      <div className="d-flex">
        <div className="canopy-mr-4x">
          <CanopyIcon
            name="shield-slash"
            size="medium"
            fill="canopyColorPrimitivesYellow62"
            className="flex-shrink-0"
          ></CanopyIcon>
        </div>
        <div className="canopy-mr-6x">
          <strong>Warning:</strong> {selectedSupplier.name} may be unable to
          fulfill your order due to insurance requirements. We can redirect your
          order to a supplier that’s preferred by your patient’s insurance.
        </div>
        <CanopyButton
          variant="warning"
          className="flex-shrink-0 align-self-center"
          onClick={() => {
            showSupplierSelect(true)
            void trackEvent(
              EventCategory.SupplierSelection,
              "banner-redirect-to-preferred-supplier-clicked"
            )
            if (preferredSuppliers.length === 1) {
              setFieldValue("supplierId", preferredSuppliers[0].externalId)
            }
          }}
        >
          Redirect to Health Plan Preferred Supplier
        </CanopyButton>
      </div>
    </Alert>
  )
}

function SelectedSupplierSummary({
  preferredSupplierSelected,
  selectedSupplier,
  availableSuppliers,
  preferredSuppliers,
  showSupplierSelect,
}: Props) {
  const showPreferredSupplierRedirectWarning =
    preferredSuppliers.length > 0 && !preferredSupplierSelected

  return showPreferredSupplierRedirectWarning ? (
    PreferredSupplierRedirect(
      selectedSupplier,
      preferredSuppliers,
      showSupplierSelect
    )
  ) : (
    <div className="form-group">
      <div className="well well-expand bg-white">
        Provided by {selectedSupplier.name}
        {availableSuppliers.length > 1 && (
          <a
            className="link float-right"
            onClick={() => {
              trackEvent(
                EventCategory.ProductConfiguration,
                "change-supplier-clicked"
              )
              showSupplierSelect(true)
            }}
          >
            Change
          </a>
        )}
      </div>
    </div>
  )
}

export default SelectedSupplierSummary
