import React from "react"
import EditButton from "./EditButton"

type Props = {
  header: string
  onEditButtonClick(): void
  showEditButton?: boolean
}

const ProductSummarySection: React.FC<Props> = ({
  header,
  onEditButtonClick,
  showEditButton = true,
  children,
}) => {
  return (
    <div className="well well-expand mb-4">
      <div className="row">
        <div className="col-9">
          <h3>{header}</h3>
          {children}
        </div>
        {showEditButton && (
          <div className="col-3">
            <EditButton onClick={onEditButtonClick} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductSummarySection
